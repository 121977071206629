var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { liveStreamEventList } from "../../../store/sportsbook.store";
import Play from "../../../assets/feather/play.svg";
import _ from "lodash";
import { ServerDate, useForceUpdate } from "../../../lib/misc.functions";
import iconRight from "../../../assets/feather/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import { setFilterLiveStream } from "../../../services/sportsbook.services";
export default function LiveStreamWidget() {
    var _a = useState(), showingEvent = _a[0], setShowingEvent = _a[1];
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        if (liveStreamEventList.length > 0) {
            setShowingEvent(_.orderBy(liveStreamEventList, ['eventDate'])[0]);
        }
    }, []);
    if (liveStreamEventList.length > 0) {
        return (_jsx("div", __assign({ className: "live-stream-widget " + (innerWidth > 968 ? "widget ml-1 mr-1 mb-2 mt-0" : ""), onClick: function () {
                setFilterLiveStream(true);
                forceUpdate();
                navigate('iddaa/canli/tumu');
            } }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("div", __assign({ className: "liveStreamBadge" }, { children: _jsx("img", { height: 13, src: Play }, void 0) }), void 0), _jsxs("div", __assign({ className: "d-flex flex-column ml-2 flex-fill w-75" }, { children: [_jsxs("h4", __assign({ className: "mb-0" }, { children: [liveStreamEventList.length, " Ma\u00E7\u0131 Canl\u0131 \u0130zle"] }), void 0), _jsxs("div", __assign({ className: "d-flex align-items-center " }, { children: [showingEvent && _jsx("div", { style: {
                                            width: "12px",
                                            height: "12px",
                                            filter: "invert(1)"
                                        }, className: "icon flex-shrink-0 sm game-event-icon mr-1 " + showingEvent.sportSlug }, void 0), showingEvent && _jsx("div", __assign({ className: "nowrap" }, { children: showingEvent.eventName }), void 0), showingEvent && _jsx(CountDown, { eventDate: showingEvent.eventDate }, void 0)] }), void 0)] }), void 0), _jsx("div", { children: _jsx("img", { className: "invert-color", style: { height: 23 }, src: iconRight }, void 0) }, void 0)] }), void 0) }), void 0));
    }
    else {
        return _jsx(React.Fragment, {}, void 0);
    }
}
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
var countDownTimer;
export function CountDown(props) {
    var _a = useState(), liveStreamCountDown = _a[0], setLiveStreamCountDown = _a[1];
    var today = ServerDate();
    var eventDate = new Date(props.eventDate);
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    var secoundsLeft = (eventDate.getTime() - today.getTime()) / 1000;
    useEffect(function () {
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
        return function () {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
            clearTimeout(countDownTimer);
        };
    }, [props.eventDate]);
    clearTimeout(countDownTimer);
    countDownTimer = setTimeout(function () {
        if (secoundsLeft <= 0) {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
        }
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
    }, 1000);
    if (!liveStreamCountDown || liveStreamCountDown.s < 0) {
        return _jsx("div", __assign({ className: "ml-2 blink " + (isMobile ? "w-40" : "w-25") }, { children: _jsx("span", __assign({ className: "fw-bold text-danger" }, { children: "Hemen \u0130zle" }), void 0) }), void 0);
    }
    return (_jsx("div", __assign({ className: "ml-2" }, { children: _jsxs("span", __assign({ className: "fw-bold" }, { children: [liveStreamCountDown.h, ":", liveStreamCountDown.m, ":", liveStreamCountDown.s] }), void 0) }), void 0));
}
