var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TrashRed from "../../../assets/feather/trash-2-red.svg";
import { removeFromBetSlip, setBanker } from "../../../services/betslip.services";
import Lock from "../../../assets/feather/lock.svg";
import { CustomMarketName, handicappedMarkets, hideSpecialOddsValueOutcomeName, marketNamesMappingData } from "../../../definitions/constants/market-categories.constant";
import { marketNameTransform } from "../../../services/sportsbook.services";
import { useNavigate } from "react-router";
export function BetSlipItem(props) {
    var item = props.item;
    var navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "betSlipItem" }, { children: [_jsxs("div", __assign({ className: "firstCol" }, { children: [_jsx("div", __assign({ className: "mbs mbs-".concat(item.market.minCombiCount) }, { children: item.market.minCombiCount }), void 0), _jsx("button", __assign({ onClick: function () {
                                setBanker(item);
                            }, className: "banker " + (item.isBanker ? 'selected' : '') }, { children: "B" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "secondCol", onClick: function () {
                        navigate('/iddaa/' + item.sportEvent.sportSlug + '/' + (item.sportEvent.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + item.sportEvent.competitionId + '/' + item.sportEvent.eventId);
                    } }, { children: [_jsxs("div", __assign({ className: "nowrap eventName" }, { children: [Number(item.sportEvent.bettingPhase) === 1 && item.sportEvent.currentScore ?
                                    _jsx("span", __assign({ className: "scores" }, { children: item.sportEvent.currentScore }), void 0) : _jsx(_Fragment, {}, void 0), item.sportEvent.eventName] }), void 0), _jsx("div", __assign({ className: "delete text-right" }, { children: _jsx("img", { className: "delete", src: TrashRed, onClick: function () {
                                    removeFromBetSlip(item.sportEvent, item.market, item.outcome);
                                } }, void 0) }), void 0), _jsxs("div", { children: [item.sportEvent.eventType !== '2' ?
                                    _jsx("div", __assign({ className: "float-left marketName", style: { maxWidth: "calc( 100% - 60px)" } }, { children: handicappedMarkets.includes(item.market.marketType + '-' + item.market.marketSubType) ?
                                            _jsxs(_Fragment, { children: ["Handikap: ", _jsx("b", { children: marketNameTransform(item.outcome.outcomeName, item.market.specialOddsValue, item.market) }, void 0)] }, void 0) :
                                            _jsxs(_Fragment, { children: [marketNamesMappingData[item.market.marketType + '-' + item.market.marketSubType] ? marketNamesMappingData[item.market.marketType + '-' + item.market.marketSubType].name : CustomMarketName(item.market.marketType + '-' + item.market.marketSubType, item.market.marketName, item.market.specialOddsValue), " : ", _jsxs("b", { children: [item.outcome.outcomeName, " ", !hideSpecialOddsValueOutcomeName.includes(item.market.marketType + '-' + item.market.marketSubType) && item.market.specialOddsValue && !item.outcome.outcomeName.includes(item.market.specialOddsValue.toString().replaceAll('.', ',')) ? item.market.specialOddsValue : ''] }, void 0)] }, void 0) }), void 0)
                                    : _jsx(_Fragment, { children: item.outcome.outcomeName }, void 0), _jsx("div", __assign({ className: "float-right text-right odd ".concat(item.outcome.isUpdated), style: { "width": "60px" } }, { children: item.market.marketStatus < 0 ? _jsx("img", { src: Lock, className: "invert-color" }, void 0) : _jsx(_Fragment, { children: Number(item.outcome.fixedOddsWeb) > 1 && item.market.marketStatus !== 0 && item.market.marketStatus < 2 ? Number(item.outcome.fixedOddsWeb).toFixed(2) :
                                            _jsx("img", { src: Lock, className: "invert-color" }, void 0) }, void 0) }), void 0)] }, void 0)] }), void 0), _jsx("div", { className: "clearfix" }, void 0), item.errorString ? _jsx("div", __assign({ className: "alert-danger" }, { children: item.errorString }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }, void 0));
}
