var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import CheckCircle from '../../../assets/feather/check-circle.svg';
import { HelpApi } from '../help.api';
import { betSlipVisibleSubject } from '../../../store/betslip.store';
var AllIddia = function () {
    var _a = useState([]), allIddaaList = _a[0], setAllIddaaList = _a[1];
    var _b = useState(null), helpData = _b[0], setHelpData = _b[1];
    useEffect(function () {
        if (!allIddaaList.length) {
            HelpApi.alliIddaa().then(function (result) {
                setAllIddaaList(result);
            });
        }
        betSlipVisibleSubject.next(false);
    }, [allIddaaList]);
    useEffect(function () {
        if (allIddaaList.length > 0) {
            var defaultItem = allIddaaList.find(function (x) { return x.id === 1; });
            setHelpData(defaultItem ? defaultItem.markets : null);
        }
    }, [allIddaaList]);
    function handleSelectChange(event) {
        var selectedItem = allIddaaList.find(function (x) { return x.id === Number(event.target.value); });
        setHelpData(selectedItem ? selectedItem.markets : null);
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "T\u00FCm \u0130ddaa Oyunlar\u0131 (Yeni D\u00F6nem)" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("select", __assign({ onChange: function (e) { return handleSelectChange(e); } }, { children: allIddaaList &&
                                allIddaaList.map(function (data) { return (_jsx("option", __assign({ value: data.id }, { children: data.name }), data.id)); }) }), void 0), _jsx("h3", {}, void 0), _jsxs("table", __assign({ className: "table" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Market Ad\u0131" }, void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Ma\u00E7 \u00D6n\u00FC" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Canl\u0131" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Uzatmalar Dahil" }), void 0)] }, void 0) }, void 0), _jsx("tbody", { children: helpData &&
                                        helpData.map(function (question, key) {
                                            return (_jsxs("tr", { children: [_jsx("td", { children: question.market }, void 0), _jsx("td", __assign({ className: "text-center" }, { children: question.mac_onu == 1 ? (_jsx("img", { src: CheckCircle, width: 16 }, void 0)) : null }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: question.canli == 1 ? (_jsx("img", { src: CheckCircle, width: 16 }, void 0)) : null }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: question.uzatma == 1 ? (_jsx("img", { src: CheckCircle, width: 16 }, void 0)) : null }), void 0)] }, "helpAllIddia".concat(key)));
                                        }) }, void 0)] }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default AllIddia;
