var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import './games.css';
import { useNavigate } from "react-router-dom";
import { initialLeftMenu, sportsMenu, slugify } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { env } from "../../definitions/global.vars";
import { ApiService } from "../../lib/http.service";
import { gameList } from "../../store/static.pages.store";
export function GamesList() {
    var navigate = useNavigate();
    var _a = useState([]), gamePageList = _a[0], setGamePageList = _a[1];
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var api = new ApiService();
        api.start("get", "".concat(env.core_api, "/games/api/list-game/"), {}, false)
            .then(function (res) {
            setGamePageList(res.data);
            gameList.splice(0, gameList.length);
            gameList.push.apply(gameList, res.data);
        });
    }, []);
    return _jsx("div", __assign({ className: "game-list-page px-lg-3" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "card w-100 mt-sm-0" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h3", __assign({ className: "float-left" }, { children: "\u015Eans Oyunlar\u0131" }), void 0) }), void 0), _jsx("div", __assign({ className: "card-body pt-sm-1 w-100" }, { children: _jsx("div", __assign({ className: "row" }, { children: gamePageList.map(function (item, index) {
                                return _jsx("div", __assign({ className: "col-md-3  col-sm-6 my-3 my-sm-1", onClick: function () {
                                        if (item.is_active) {
                                            navigate("/".concat(slugify(item.code)));
                                        }
                                    } }, { children: _jsxs("div", __assign({ className: "card cursor-pointer product", style: { background: 'var(--color-bg-light)' } }, { children: [!item.is_active ?
                                                _jsx("div", __assign({ className: "ribbon ribbon-coming-soon" }, { children: _jsx("span", { children: "YAKINDA" }, void 0) }), void 0) : null, _jsx("img", { className: "photo", loading: "lazy", src: "".concat(env.core_api).concat(item.image), alt: "" }, void 0), _jsx("div", __assign({ className: 'order-ticket mt-3' }, { children: _jsx("input", { className: 'btn btn-primary w-50', type: "button", value: 'HEMEN OYNA', disabled: !item.is_active }, void 0) }), void 0)] }), void 0) }), index);
                            }) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0);
}
