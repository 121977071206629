var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { currencyFormat, dateFormat, dateTimeFormat, useForceUpdate } from "../../../lib/misc.functions";
import { env, } from "../../../definitions/global.vars";
import { eventMapping, marketIdMapping } from "../../../store/sportsbook.store";
import { askToDelete, askToReport, followActions, likePost, shareOnSocial, } from "../../../services/sosyoley.services";
import { addToBetSlip } from "../../../services/betslip.services";
import { sosyoleyEventIdMapping } from "../subpages/posts";
import DefaultAvatar from "../../../assets/img/avatar.png";
import { location, member, useAuthContext } from "../../../store/login.store";
import HeartFill from "../../../assets/feather/heart-fill.svg";
import Heart from "../../../assets/feather/heart.svg";
import Flag from "../../../assets/feather/flag.svg";
import Twitter from "../../../assets/feather/twitter.svg";
import Whatsapp from "../../../assets/feather/share-2.svg";
import Share from "../../../assets/feather/share-2.svg";
import TrashRed from "../../../assets/feather/trash-2-red.svg";
import iconComment from "../../../assets/feather/message-circle.svg";
import { inProgress } from "../../../app";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { betSlipActionSubject, BetSlipOption } from "../../../store/betslip.store";
import { CouponEventRow } from "./event";
import { Location } from "../../../models/user.model";
import { Storage } from "../../../lib/localstorege.service";
export default function PostComponent(props) {
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var isAuthenticated = useAuthContext().isAuthenticated;
    var parentForceUpdate = props.forceUpdate;
    // @ts-ignore
    var _a = props.modalState, postCommentModal = _a.postCommentModal, setPostCommentModal = _a.setPostCommentModal;
    var post = props.post;
    var posts = props.posts;
    var playSharedCoupon = function (coupon) {
        Storage.set('play_coupon', coupon.coupon_id);
        BetSlipOption.betSlipQuantity = coupon.quantity;
        BetSlipOption.betSlipMultiply = coupon.multiply;
        coupon.slip_rows.forEach(function (item) {
            if (item.playableObject && item.playableObject.sbEvent) {
                addToBetSlip(item.playableObject.sbEvent, item.playableObject.sbMarket, item.playableObject.sbOutcome, 'toggle', item.banker);
            }
        });
        if (coupon.system !== "") {
            var systemOptions = coupon.system.split('-');
            var _loop_1 = function (i) {
                BetSlipOption.systemOptions.filter(function (s) {
                    if (s.sysNum === Number(i)) {
                        s.sysSelected = true;
                    }
                    return s;
                });
            };
            for (var _i = 0, systemOptions_1 = systemOptions; _i < systemOptions_1.length; _i++) {
                var i = systemOptions_1[_i];
                _loop_1(i);
            }
            betSlipActionSubject.next({
                action: 'calc',
                betSlip: null,
            });
        }
    };
    if (!post) {
        return _jsx(_Fragment, {}, void 0);
    }
    // console.log(post)
    if (post.coupon) {
        post.coupon.slip_rows.forEach(function (r) {
            var sbEvent = eventMapping[r.event_id.toString()];
            if (sbEvent) {
                var sbMarket = marketIdMapping[sbEvent.eventId][r.market_id.toString()];
                if (sbMarket) {
                    var sbOutcome = sbMarket.marketOutcome.find(function (o) { return o.outcomeNo === r.outcome_no; });
                    r['playableObject'] = { sbEvent: sbEvent, sbMarket: sbMarket, sbOutcome: sbOutcome };
                    if (post.coupon) {
                        post.coupon["playable"] = true;
                    }
                }
            }
        });
    }
    function onLocation(selectlocation) {
        var object = new Location();
        object.path = selectlocation;
        Object.assign(location, object);
        navigate('/giris-yap');
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "header" }, { children: [post.member ?
                        _jsxs("div", __assign({ className: "member" }, { children: [post.member.photo ? _jsx("img", { className: "avatar", src: "".concat(env.core_api).concat(post.member.photo), onError: function (e) {
                                        try {
                                            if (e.type == 'error') {
                                                // @ts-ignore
                                                e.target.src = DefaultAvatar;
                                            }
                                        }
                                        catch (e) {
                                        }
                                    } }, void 0) :
                                    _jsx("img", { className: "avatar", src: DefaultAvatar, width: 45 }, void 0), _jsxs("div", __assign({ className: post.coupon ? 'align-items-center nickname' : "nickname", style: { height: "100%" } }, { children: [_jsxs(Link, __assign({ to: '/sosyoley/' + (post.member_id === member.id ? 'profilim' : 'profil') + '/' + post.member_id + '/kuponlarim/' }, { children: ["@", post.member.nick_name] }), void 0), _jsx("br", {}, void 0), _jsx("div", __assign({ className: "date" }, { children: props.subpage == 'profil' || props.subpage == 'profilim' ? _jsx(_Fragment, {}, void 0) :
                                                member.id ? _jsx(_Fragment, { children: !member.following.includes(Number(post.member_id)) ?
                                                        (post.member_id !== member.id) ? (_jsx("button", __assign({ onClick: function (e) {
                                                                followActions(Number(post.member_id), 'add', e).then(function () {
                                                                    parentForceUpdate();
                                                                });
                                                            }, className: "btn btn-small btn-warning" }, { children: "Takip Et" }), void 0)) : (_jsx(_Fragment, {}, void 0))
                                                        :
                                                            _jsx("button", __assign({ onClick: function (e) {
                                                                    followActions(Number(post.member_id), 'unfollow', e).then(function () {
                                                                        parentForceUpdate();
                                                                    });
                                                                }, className: "btn btn-small btn-outline-warning" }, { children: "Takibi B\u0131rak" }), void 0) }, void 0) :
                                                    _jsx(_Fragment, { children: _jsx("button", __assign({ onClick: function () {
                                                                onLocation(window.location.pathname);
                                                            }, className: "btn btn-small btn-warning" }, { children: "Takip Et" }), void 0) }, void 0) }), void 0)] }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0), isAuthenticated && post.coupon ?
                        _jsxs("div", __assign({ className: "button ml-auto sosyoley-sharebtn" }, { children: [_jsxs("div", __assign({ className: "icon" }, { children: [_jsx("img", { src: Share, width: 17, className: "shere invert-color", alt: "" }, void 0), _jsx("button", __assign({ className: 'icon-shere', id: 'twitterShare' + post.post_id, style: { background: 'transparent', border: '0' }, disabled: false, onClick: function () {
                                                shareOnSocial(post, 0, 'post');
                                            } }, { children: _jsx("img", { className: "invert-color cursor-pointer filter-red ", src: Twitter, width: 17 }, void 0) }), void 0), window.innerWidth < 768 ?
                                            _jsx(_Fragment, { children: _jsxs("button", __assign({ className: 'icon-shere', id: 'waButton' + post.post_id, style: { background: 'transparent', border: '0' }, disabled: false, onClick: function () {
                                                        shareOnSocial(post, 1, 'post');
                                                    } }, { children: [_jsx("img", { className: "invert-color cursor-pointer filter-red visible-sm", src: Whatsapp, width: 17 }, void 0), " "] }), void 0) }, void 0) : null] }), void 0), "Kuponu Payla\u015F"] }), void 0)
                        : null] }), void 0), _jsx("div", __assign({ className: "comment pb-0 pt-0", style: { marginTop: post.coupon ? "-5px" : "10px" } }, { children: sosyoleyEventIdMapping[Number(post.obj_id)] ? (_jsxs(_Fragment, { children: [Number(sosyoleyEventIdMapping[Number(post.obj_id)].isLiveEvent) === 1 ?
                            _jsx("i", __assign({ className: "is-live float-left" }, { children: "Canl\u0131" }), void 0) : _jsx(_Fragment, {}, void 0), Number(sosyoleyEventIdMapping[Number(post.obj_id)].bettingPhase) === 1 ?
                            _jsx("i", __assign({ className: "is-live float-left minute-blink mt-0" }, { children: sosyoleyEventIdMapping[Number(post.obj_id)].currentScore }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { className: "visible-sm clearfix" }, void 0), _jsxs(Link, __assign({ to: '/iddaa/' + sosyoleyEventIdMapping[Number(post.obj_id)].sportSlug + '/tumu/' + sosyoleyEventIdMapping[Number(post.obj_id)].competitionId + '/' + sosyoleyEventIdMapping[post.obj_id].eventId }, { children: ["# ", sosyoleyEventIdMapping[Number(post.obj_id)].eventName] }), void 0)] }, void 0)) : '' }), void 0), post.comment && post.comment !== '' ?
                _jsx("div", { style: { padding: '12px 15px', marginLeft: '40px' }, dangerouslySetInnerHTML: { __html: post.comment } }, void 0) : _jsx(_Fragment, {}, void 0), post.content_type === 2 && post.coupon ? _jsx("div", __assign({ id: '#slip_' + post.post_id }, { children: _jsxs("div", __assign({ className: "coupon" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsxs("div", __assign({ className: "desc" }, { children: [_jsx("div", __assign({ className: "approve-date hidden-sm" }, { children: dateTimeFormat(post.coupon.approve_date) }), void 0), _jsx("div", __assign({ className: "approve-date visible-sm" }, { children: dateFormat(post.coupon.approve_date) }), void 0), _jsxs("div", __assign({ className: "profit" }, { children: [currencyFormat(post.coupon.profit * Number(post.coupon.quantity)), "\u20BA ", _jsx("span", __assign({ className: "hidden-sm" }, { children: "kazand\u0131r\u0131yor" }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "options" }, { children: [_jsxs("div", { children: [_jsx("span", { children: Number(post.coupon.total_ratio).toFixed(2) }, void 0), "Oran"] }, void 0), _jsxs("div", { children: [_jsx("span", { children: post.coupon.multiply ? currencyFormat(Number(post.coupon.amount)) : '' }, void 0), "Tutar\u0131"] }, void 0), _jsxs("div", { children: [_jsx("span", { children: Number(post.coupon.hit) + 1 }, void 0), "Oynanma"] }, void 0)] }), void 0)] }), void 0), _jsx("table", __assign({ className: "table events" }, { children: _jsx("tbody", { children: post.coupon.slip_rows.map(function (row, rowKey) {
                                    var event = sosyoleyEventIdMapping[Number(row.event_id)];
                                    return (_jsx("tr", { children: _jsx(CouponEventRow, { row: row, event: event, post: post }, void 0) }, rowKey));
                                }) }, void 0) }), void 0), _jsxs("div", __assign({ className: "footer" }, { children: [_jsxs("div", { children: [_jsx("span", { children: "Sistem:" }, void 0), " ", post.coupon.system && post.coupon.system !== '' ? post.coupon.system : 'Kombine'] }, void 0), _jsxs("div", { children: [_jsx("span", { children: "Misli:" }, void 0), " ", post.coupon.multiply] }, void 0), _jsxs("div", { children: [_jsx("span", { children: "Adet:" }, void 0), " ", post.coupon.quantity] }, void 0)] }), void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "footer mt-2 d-flex" }, { children: [_jsx("div", __assign({ className: "col-md-2 col-sm-1 px-0" }, { children: post.comment_perm ? _jsxs(_Fragment, { children: [_jsx("span", __assign({ className: 'post-icon' }, { children: _jsx("img", { className: "invert-color cursor-pointer filter-red", style: { verticalAlign: 'sub' }, src: iconComment, width: 17, onClick: function () {
                                            setPostCommentModal(post);
                                        } }, void 0) }), void 0), _jsx("i", __assign({ className: 'mr-1 post-count', style: { verticalAlign: 'middle' } }, { children: post.comment_count }), void 0)] }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0), _jsxs("div", __assign({ className: "col-md-2 col-sm-1 px-0" }, { children: [_jsxs("span", __assign({ className: 'post-icon' }, { children: [" ", _jsx("img", { className: "invert-color cursor-pointer filter-red", src: post.is_liked && post.is_liked[0] === 1 ? HeartFill : Heart, width: 17, onClick: function () {
                                            if (!inProgress.state) {
                                                inProgress.state = true;
                                                likePost(post).then(function () {
                                                    inProgress.state = false;
                                                    forceUpdate();
                                                });
                                            }
                                        } }, void 0)] }), void 0), _jsx("i", __assign({ className: 'mr-1 post-count', style: { verticalAlign: 'middle' } }, { children: post.like_count }), void 0)] }), void 0), _jsx("div", __assign({ className: "col-md-1 col-sm-1 px-0 " }, { children: _jsx("span", __assign({ className: 'post-icon' }, { children: _jsx("img", { className: "invert-color cursor-pointer filter-red", src: Flag, width: 17, onClick: function () {
                                    askToReport(post);
                                } }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "col-md-2 col-sm-1" }, { children: post.member_id === member.id ?
                            _jsx("img", { className: "invert-color cursor-pointer filter-red", src: TrashRed, width: 17, onClick: function () {
                                    askToDelete(post, posts, parentForceUpdate);
                                } }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0), post.coupon ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "col text-right pr-0" }, { children: post.coupon.playable ? _jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn btn-primary", onClick: function () {
                                        playSharedCoupon(post.coupon);
                                    } }, { children: "Kuponu Oyna" }), void 0) }, void 0) : _jsxs(_Fragment, { children: [post.coupon.status === 1 ?
                                        _jsx("div", __assign({ className: "btn btn-default cursor-none" }, { children: "Devam Ediyor" }), void 0) : _jsx(_Fragment, {}, void 0), post.coupon.status === 2 ? _jsx("div", __assign({ className: "btn btn-success cursor-none" }, { children: "Kazand\u0131" }), void 0) : _jsx(_Fragment, {}, void 0), post.coupon.status === 3 ? _jsx("div", __assign({ className: "btn btn-danger cursor-none" }, { children: "Kaybetti" }), void 0) : _jsx(_Fragment, {}, void 0)] }, void 0) }), void 0) }, void 0) :
                        post.coupon ? _jsx(_Fragment, {}, void 0) : _jsx("div", __assign({ className: 'col justify-content-end' }, { children: _jsx("div", __assign({ className: " text-right date", style: {
                                    fontWeight: '500',
                                    color: "var(--color-text-medium)"
                                } }, { children: dateTimeFormat(post.create_date) }), void 0) }), void 0)] }), void 0)] }, void 0));
}
