var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getLeaderBoards, getMemberInfo, } from "../../../services/sosyoley.services";
import "../../../assets/css/sport.color.icon.css";
import { Link } from "react-router-dom";
import DefaultAvatar from "../../../assets/img/avatar.png";
import { env } from '../../../definitions/global.vars';
import { location, member } from "../../../store/login.store";
import { followActions } from "../../../services/sosyoley.services";
import { useNavigate } from "react-router";
import { Location } from "../../../models/user.model";
import { useForceUpdate } from "../../../lib/misc.functions";
export default function LeaderBoards() {
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var _a = useState(1), contentType = _a[0], setContentType = _a[1];
    var _b = useState("most_winners"), status = _b[0], setStatus = _b[1];
    var _c = useState([]), memberInfo = _c[0], setMemberInfo = _c[1];
    var _d = useState(null), leaderBoardsData = _d[0], setLeaderBoardsData = _d[1];
    var data = {
        limit: 10,
        data_type: contentType
    };
    function getLeaderBoard() {
        data.data_type = contentType;
        var api = "/misc/api/leader-boards/" + contentType + "/";
        getLeaderBoards(api, data).then(function (response) {
            setLeaderBoardsData(response);
            getMembers(response, status);
        });
    }
    useEffect(function () {
        getLeaderBoard();
    }, [contentType]);
    function getMembers(leaderBoard, reqStatus) {
        var profileMembers = [];
        leaderBoard ? leaderBoard[reqStatus].forEach(function (element) {
            profileMembers.push(element.member_id);
        }) : null;
        profileMembers ? getMemberInfo({ member_id: profileMembers.join(',') }).then(function (result) {
            setMemberInfo(result);
        }) : null;
    }
    var statusText = {
        most_winners: "kazandı",
        most_raters: "oranla kazandı",
        made_most_people_win: "kişiye kazandırdı",
    };
    function onLocation(selectlocation) {
        var object = new Location();
        object.path = selectlocation;
        Object.assign(location, object);
        navigate('/giris-yap');
    }
    return (_jsx("div", __assign({ className: "card social-profile" }, { children: _jsxs("div", __assign({ className: "card-title" }, { children: [_jsx("h2", __assign({ className: "w-100" }, { children: " Zirvedekiler " }), void 0), _jsxs("div", __assign({ className: "actions" }, { children: [_jsx("a", __assign({ onClick: function () { return setContentType(1); }, className: "tab " + (contentType === 1 ? 'active' : '') }, { children: "D\u00FCn" }), void 0), _jsx("a", __assign({ onClick: function () { return setContentType(2); }, className: "tab " + (contentType === 2 ? 'active' : '') }, { children: "Bu Hafta" }), void 0)] }), void 0), _jsx("br", {}, void 0), _jsxs("div", __assign({ className: "actions" }, { children: [_jsx("a", __assign({ onClick: function () { setStatus('most_winners'); getMembers(leaderBoardsData, 'most_winners'); }, className: "tab " + (status === 'most_winners' ? 'active' : '') }, { children: "En \u00C7ok Kazanan Zirvesi" }), void 0), _jsx("a", __assign({ onClick: function () { setStatus('most_raters'); getMembers(leaderBoardsData, 'most_raters'); }, className: "tab " + (status === 'most_raters' ? 'active' : '') }, { children: "En Y\u00FCksek Oran Zirvesi" }), void 0), _jsx("a", __assign({ onClick: function () { setStatus('made_most_people_win'); getMembers(leaderBoardsData, 'made_most_people_win'); }, className: "tab " + (status === 'made_most_people_win' ? 'active' : '') }, { children: "En \u00C7ok Ki\u015Fiye Kazand\u0131ran Zirvesi" }), void 0)] }), void 0), leaderBoardsData && memberInfo.length > 0 ? leaderBoardsData[status].map(function (leaderBoard, key) {
                    var obj = memberInfo.find(function (x) { return x.member_id === leaderBoard.member_id; });
                    if (obj && Number(leaderBoard.value !== 0)) {
                        return (_jsx("div", __assign({ className: "post" }, { children: _jsxs("div", __assign({ className: "header" }, { children: [obj ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "member w-100 my-2 ml-4" }, { children: [obj.photo ? _jsx("img", { className: "avatar", src: "".concat(env.core_api).concat(obj.photo), onError: function (e) {
                                                        try {
                                                            if (e.type == 'error') {
                                                                // @ts-ignore
                                                                e.target.src = DefaultAvatar;
                                                            }
                                                        }
                                                        catch (e) {
                                                        }
                                                    }, width: 45 }, void 0) :
                                                    _jsx("img", { className: "avatar", src: DefaultAvatar, width: 45 }, void 0), _jsxs("div", __assign({ className: "nickname" }, { children: [_jsxs(Link, __assign({ to: "/sosyoley/profil/" + obj.member_id + "/kuponlarim/" }, { children: ["@", obj.nick_name] }), void 0), _jsx("br", {}, void 0), _jsxs("p", __assign({ className: "mb-1", style: { color: 'var(--color-success)' } }, { children: [" ", status === 'most_winners' ? leaderBoard.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(",", "!").replace(".", ",").replace("!", ".") + " TL" : leaderBoard.value, " ", statusText[status]] }), void 0)] }), void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "date m-auto" }, { children: member.id ? _jsx(_Fragment, { children: !member.following.includes(Number(obj.member_id)) ?
                                                (obj.member_id !== member.id) ? (_jsx("button", __assign({ onClick: function (e) {
                                                        followActions(Number(obj.member_id), 'add', e).then(function () {
                                                            forceUpdate();
                                                        });
                                                    }, className: "btn btn-small btn-warning mr-sm-5" }, { children: "Takip Et" }), void 0)) : (_jsx(_Fragment, {}, void 0))
                                                :
                                                    _jsx("button", __assign({ onClick: function (e) {
                                                            followActions(Number(obj.member_id), 'unfollow', e).then(function () {
                                                                forceUpdate();
                                                            });
                                                        }, className: "btn btn-small btn-outline-warning mr-sm-5" }, { children: "Takibi B\u0131rak" }), void 0) }, void 0) :
                                            _jsx(_Fragment, { children: _jsx("button", __assign({ onClick: function () { onLocation(window.location.pathname); }, className: "btn btn-small btn-warning mr-sm-5" }, { children: "Takip Et" }), void 0) }, void 0) }), void 0)] }), void 0) }), key + "leaderboards"));
                    }
                    else {
                        return (_jsx(_Fragment, {}, void 0));
                    }
                }) : null] }), void 0) }), void 0));
}
