export var env = {
    accounting_api: 'https://api.oley.com',
    list_api: 'https://api.oley.com',
    accounting_wss: 'wss://feed.oley.com/stream',
    core_api: 'https://api.oley.com',
    misc_api: 'https://api.oley.com',
    auth_api: 'https://api.oley.com',
    sosyoley_api: 'https://api.oley.com',
    sportsbook_api: 'https://sportsbook.oley.com',
    currentbook_api: 'https://currentbook.oley.com',
    sportsbook_wss: 'wss://sportsbook-ws.oley.com/stream/bulletin-v3/',
    stream_url: 'https://stream.oley.com',
    website_url: 'https://oley.com',
    affiliate_url: 'https://affiliate.oley.com',
    cdn_url: 'https://cdn.oley.com',
    scores: 'https://scores.oley.com',
    tjk_api: 'https://tjk.oley.com',
    tjk: false,
};
export var months = [
    { val: '01', name: 'Ocak' },
    { val: '02', name: 'Şubat' },
    { val: '03', name: 'Mart' },
    { val: '04', name: 'Nisan' },
    { val: '05', name: 'Mayıs' },
    { val: '06', name: 'Haziran' },
    { val: '07', name: 'Temmuz' },
    { val: '08', name: 'Ağustos' },
    { val: '09', name: 'Eylül' },
    { val: '10', name: 'Ekim' },
    { val: '11', name: 'Kasım' },
    { val: '12', name: 'Aralık' }
];
export var UnitLabel = {
    0: 'TL',
    1: 'OP',
};
export var TransactionTypeLabel = {
    0: 'Devir',
    1: 'Para yatırma',
    2: 'Para yatırma (İadesi)',
    3: 'Para çekme',
    4: 'Para çekme (iadesi)',
    5: 'İddaa Kupon oynama',
    6: 'İddaa Kupon iptali',
    7: 'İddaa Kupon kazanç',
    8: 'Spor Toto Kupon oynama',
    9: 'Spor Toto Kupon iptali',
    10: 'Spor Toto Kupon kazanç',
    11: 'Milli Piyango Satışı',
    12: 'Milli Piyango kazanç',
    13: 'Vergi Kesintisi',
    14: 'Çekim komisyonu',
    15: 'Banka işlem bedeli',
    16: 'Şans Girişim Ödemesi',
    17: 'İşletme Giderleri',
    18: 'Hesaplar Arası Virman',
    19: 'OleyPuan',
    20: 'Düzeltme',
    21: 'Süper Sayısal Loto oynama',
    22: 'Süper Şans Topu oynama',
    23: 'Süper On Numara oynama',
    24: 'Para Loto oynama',
    25: 'Süper Sayısal Loto iadesi',
    26: 'Süper Şans Topu iadesi',
    27: 'Süper On Numara iadesi',
    28: 'Para Loto iadesi',
    29: 'Süper Sayısal Loto kazanç',
    30: 'Süper Şans Topu kazanç',
    31: 'Süper On Numara kazanç',
    32: 'Para Loto kazanç',
    33: 'Süper Şans Oyunu kazanç',
    37: 'Eşya Piyangosu Oynama'
};
export var longDays = {
    0: 'Pazar',
    1: 'Pazartesi',
    2: 'Salı',
    3: 'Çarşamba',
    4: 'Perşembe',
    5: 'Cuma',
    6: 'Cumartesi',
};
export var shortDays = {
    0: 'Pzr',
    1: 'Pts',
    2: 'Sal',
    3: 'Çar',
    4: 'Per',
    5: 'Cum',
    6: 'Cmt',
};
export var warninLogStyle = 'color: black; background: yellow; font-size: 12px; font-weight:bold';
export var successLogStyle = 'color: white; background: green; font-size: 12px; font-weight:bold';
export var errorLogStyle = 'color: white; background: red; font-size: 12px; font-weight:bold';
export var nationalities = [
    { id: 0, name: 'Afganistan' },
    { id: 1, name: 'Almanya' },
    { id: 2, name: 'ABD' },
    { id: 3, name: 'Andorra' },
    { id: 4, name: 'Angola' },
    { id: 5, name: 'Antigua ve Barbuda' },
    { id: 6, name: 'Arjantin' },
    { id: 7, name: 'Arnavutluk' },
    { id: 8, name: 'Avustralya' },
    { id: 9, name: 'Avusturya' },
    { id: 10, name: 'Azerbaycan' },
    { id: 11, name: 'Bahamalar' },
    { id: 12, name: 'Bahreyn' },
    { id: 13, name: 'Bangladeş' },
    { id: 14, name: 'Barbados' },
    { id: 15, name: 'Belarus' },
    { id: 16, name: 'Belçika' },
    { id: 17, name: 'Belize' },
    { id: 18, name: 'Benin' },
    { id: 19, name: 'Birleşik Arap Emirlikleri' },
    { id: 20, name: 'Birleşik Krallık' },
    { id: 21, name: 'Bolivya' },
    { id: 22, name: 'Bosna Hersek' },
    { id: 23, name: 'Botsvana' },
    { id: 24, name: 'Brezilya' },
    { id: 25, name: 'Brunei' },
    { id: 26, name: 'Bulgaristan' },
    { id: 27, name: 'Burkina Faso' },
    { id: 28, name: 'Burundi' },
    { id: 29, name: 'Butan' },
    { id: 30, name: 'Cabo Verde' },
    { id: 31, name: 'Cezayir' },
    { id: 32, name: 'Cibuti' },
    { id: 33, name: 'Çad' },
    { id: 34, name: 'Çek Cumhuriyeti' },
    { id: 35, name: 'Çin' },
    { id: 36, name: 'Çin Halk Cumhuriyeti Hong Kong Özel İdare Bölgesi' },
    { id: 37, name: 'Danimarka' },
    { id: 38, name: 'Demokratik Kongo' },
    { id: 39, name: 'Doğu Timor' },
    { id: 40, name: 'Dominik Cumhuriyeti' },
    { id: 41, name: 'Dominika' },
    { id: 42, name: 'Ekvator' },
    { id: 43, name: 'Ekvator Ginesi' },
    { id: 44, name: 'El Salvador' },
    { id: 45, name: 'Endonezya' },
    { id: 46, name: 'Eritre' },
    { id: 47, name: 'Ermenistan' },
    { id: 48, name: 'Estonya' },
    { id: 49, name: 'Etiyopya' },
    { id: 50, name: 'Fas' },
    { id: 51, name: 'Fiji' },
    { id: 52, name: 'Fildişi Sahili' },
    { id: 53, name: 'Filipinler' },
    { id: 54, name: 'Filistin' },
    { id: 55, name: 'Finlandiya' },
    { id: 56, name: 'Fransa' },
    { id: 57, name: 'Gabon' },
    { id: 58, name: 'Gambiya' },
    { id: 59, name: 'Gana' },
    { id: 60, name: 'Gine' },
    { id: 61, name: 'Gine-Bissau' },
    { id: 62, name: 'Grenada' },
    { id: 63, name: 'Guatemala' },
    { id: 64, name: 'Guyana' },
    { id: 65, name: 'Güney Afrika' },
    { id: 66, name: 'Güney Kıbrıs Rum Yönetimi' },
    { id: 67, name: 'Güney Kore' },
    { id: 68, name: 'Güney Sudan' },
    { id: 69, name: 'Gürcistan' },
    { id: 70, name: 'Haiti' },
    { id: 71, name: 'Hırvatistan' },
    { id: 72, name: 'Hindistan' },
    { id: 73, name: 'Hollanda' },
    { id: 74, name: 'Honduras' },
    { id: 75, name: 'Irak' },
    { id: 76, name: 'İran' },
    { id: 77, name: 'İrlanda' },
    { id: 78, name: 'İspanya' },
    { id: 79, name: 'İsrail' },
    { id: 80, name: 'İsveç' },
    { id: 81, name: 'İsviçre' },
    { id: 82, name: 'İtalya' },
    { id: 83, name: 'İzlanda' },
    { id: 84, name: 'Jamaika' },
    { id: 85, name: 'Japonya' },
    { id: 86, name: 'Kamboçya' },
    { id: 87, name: 'Kamerun' },
    { id: 88, name: 'Kanada' },
    { id: 89, name: 'Karadağ' },
    { id: 90, name: 'Katar' },
    { id: 91, name: 'Kazakistan' },
    { id: 92, name: 'Kenya' },
    { id: 93, name: 'Kırgızistan' },
    { id: 94, name: 'Kiribati' },
    { id: 95, name: 'Kolombiya' },
    { id: 96, name: 'Komor' },
    { id: 97, name: 'Kongo' },
    { id: 98, name: 'Kosova' },
    { id: 99, name: 'Kosta Rika' },
    { id: 100, name: 'Kuveyt' },
    { id: 101, name: 'KKTC' },
    { id: 102, name: 'Kuzey Kore' },
    { id: 103, name: 'Küba' },
    { id: 104, name: 'Laos' },
    { id: 105, name: 'Lesoto' },
    { id: 106, name: 'Letonya' },
    { id: 107, name: 'Liberya' },
    { id: 108, name: 'Libya' },
    { id: 109, name: 'Lihtenştayn' },
    { id: 110, name: 'Litvanya' },
    { id: 111, name: 'Lübnan' },
    { id: 112, name: 'Lüksemburg' },
    { id: 113, name: 'Macaristan' },
    { id: 114, name: 'Madagaskar' },
    { id: 115, name: 'Makedonya' },
    { id: 116, name: 'Malavi' },
    { id: 117, name: 'Maldivler' },
    { id: 118, name: 'Malezya' },
    { id: 119, name: 'Mali' },
    { id: 120, name: 'Malta' },
    { id: 121, name: 'Marşal Adaları' },
    { id: 122, name: 'Mauritius' },
    { id: 123, name: 'Meksika' },
    { id: 124, name: 'Mısır' },
    { id: 125, name: 'Mikronezya' },
    { id: 126, name: 'Moğolistan' },
    { id: 127, name: 'Moldova' },
    { id: 128, name: 'Monako' },
    { id: 129, name: 'Moritanya' },
    { id: 130, name: 'Mozambik' },
    { id: 131, name: 'Myanmar' },
    { id: 132, name: 'Namibya' },
    { id: 133, name: 'Nauru' },
    { id: 134, name: 'Nepal' },
    { id: 135, name: 'Nijer' },
    { id: 136, name: 'Nijerya' },
    { id: 137, name: 'Nikaragua' },
    { id: 138, name: 'Norveç' },
    { id: 139, name: 'Orta Afrika' },
    { id: 140, name: 'Özbekistan' },
    { id: 141, name: 'Pakistan' },
    { id: 142, name: 'Palau' },
    { id: 143, name: 'Panama' },
    { id: 144, name: 'Papua Yeni Gine' },
    { id: 145, name: 'Paraguay' },
    { id: 146, name: 'Peru' },
    { id: 147, name: 'Polonya' },
    { id: 148, name: 'Portekiz' },
    { id: 149, name: 'Romanya' },
    { id: 150, name: 'Ruanda' },
    { id: 151, name: 'Rusya Federasyonu' },
    { id: 152, name: 'Saint Kitts ve Nevis' },
    { id: 153, name: 'Saint Lucia' },
    { id: 154, name: 'Saint Vincent ve Grenadinler' },
    { id: 155, name: 'Samoa' },
    { id: 156, name: 'San Marino' },
    { id: 157, name: 'Sao Tome ve Principe' },
    { id: 158, name: 'Senegal' },
    { id: 159, name: 'Seyşeller' },
    { id: 160, name: 'Sırbistan' },
    { id: 161, name: 'Sierra Leone' },
    { id: 162, name: 'Singapur' },
    { id: 163, name: 'Slovakya' },
    { id: 164, name: 'Slovenya' },
    { id: 165, name: 'Solomon Adaları' },
    { id: 166, name: 'Somali' },
    { id: 167, name: 'Sri Lanka' },
    { id: 168, name: 'Sudan' },
    { id: 169, name: 'Surinam' },
    { id: 170, name: 'Suriye' },
    { id: 171, name: 'Suudi Arabistan' },
    { id: 172, name: 'Svaziland' },
    { id: 173, name: 'Şili' },
    { id: 174, name: 'Tacikistan' },
    { id: 175, name: 'Tanzanya' },
    { id: 176, name: 'Tayland' },
    { id: 177, name: 'Çin Tayvan' },
    { id: 178, name: 'Togo' },
    { id: 179, name: 'Tonga' },
    { id: 180, name: 'Trinidad ve Tobago' },
    { id: 181, name: 'Tunus' },
    { id: 182, name: 'Tuvalu' },
    { id: 183, name: 'Türkmenistan' },
    { id: 184, name: 'Uganda' },
    { id: 185, name: 'Ukrayna' },
    { id: 186, name: 'Umman' },
    { id: 187, name: 'Uruguay' },
    { id: 188, name: 'Ürdün' },
    { id: 189, name: 'Vanuatu' },
    { id: 190, name: 'Vatikan' },
    { id: 191, name: 'Venezuela' },
    { id: 192, name: 'Vietnam' },
    { id: 193, name: 'Yemen' },
    { id: 194, name: 'Yeni Zelanda' },
    { id: 195, name: 'Yunanistan' },
    { id: 196, name: 'Zambiya' },
    { id: 197, name: 'Zimbabve' },
];
export var professions = [
    { id: 0, name: 'Akaryakıt İstasyonu Sahibi /İşletmecisi' },
    { id: 1, name: 'Aktör/Aktrist/Yönetmen' },
    { id: 2, name: 'Antikacı' },
    { id: 3, name: 'Askeri Personel' },
    { id: 4, name: 'Askeri Personel - Albay / Yarbay' },
    { id: 5, name: 'Askeri Personel - General / Amiral' },
    { id: 6, name: 'Aşçı' },
    { id: 7, name: 'At Yetiştiricisi/Sahibi' },
    { id: 8, name: 'Belediye Başkanı / Belediye Başkan Yardımcısı' },
    { id: 9, name: 'Berber/Kuaför/Güzellik Uzmanı' },
    { id: 10, name: 'Bilim İnsanı' },
    { id: 11, name: 'Boyacı / Elektrikçi / Tesisatçı / Tamirci / Marangoz' },
    { id: 12, name: 'Çalışmıyor' },
    { id: 13, name: 'Çevirmen/Tercüman/Mütercim' },
    { id: 14, name: 'Çifçti' },
    { id: 15, name: 'Danışman' },
    { id: 16, name: 'Denetçi / Müfettiş' },
    { id: 17, name: 'Din Görevlisi' },
    { id: 18, name: 'Diplomat / Bürokrat / Konsolos / Büyükelçi' },
    { id: 19, name: 'Distribütör' },
    { id: 20, name: 'Diyetisyen' },
    { id: 21, name: 'Döviz Bürosu Sahibi' },
    { id: 22, name: 'Ekonomist' },
    { id: 23, name: 'Emekli' },
    { id: 24, name: 'Emlak Komisyoncusu' },
    { id: 25, name: 'Emniyet Görevlisi/Polis/Bekçi' },
    { id: 26, name: 'Esnaf / İş Yeri Sahibi' },
    { id: 27, name: 'Ev Hanımı' },
    { id: 28, name: 'Finans Çalışanı / Bankacı' },
    { id: 29, name: 'Finans Çalışanı / Borsacı/ Dealer / Broker' },
    { id: 30, name: 'Firma Ortağı/Sahibi' },
    { id: 31, name: 'Futbolcu' },
    { id: 32, name: 'Galerici' },
    { id: 33, name: 'Garson/Barmen' },
    { id: 34, name: 'Gümrük Müşaviri/Çalışanı' },
    { id: 35, name: 'Güvenlik Görevlisi' },
    { id: 36, name: 'Hakem' },
    { id: 37, name: 'Hemşire' },
    { id: 38, name: 'Hizmet Görevlisi/Çalışanı' },
    { id: 39, name: 'Hostes/Host' },
    { id: 40, name: 'Hukuk Bürosu Sahibi' },
    { id: 41, name: 'Hukuk Çalışanı - Avukat' },
    { id: 42, name: 'Hukuk Çalışanı - Hakim / Savcı' },
    { id: 43, name: 'Hukuk Müşaviri / Danışmanı' },
    { id: 44, name: 'İnfluncer/Youtuber/Gamer' },
    { id: 45, name: 'İstatistikçi, Kimyager, Matematikçi, Fizikçi' },
    { id: 46, name: 'İşçi' },
    { id: 47, name: 'İşletmeci' },
    { id: 48, name: 'İtfaiyeci' },
    { id: 49, name: 'Jokey' },
    { id: 50, name: 'Kurye' },
    { id: 51, name: 'Kuyumcu/Sarraf' },
    { id: 52, name: 'Mali Müşavir / Muhasebeci' },
    { id: 53, name: 'Manajer / Yapımcı / Yönetmen / Organizatör' },
    { id: 54, name: 'Manken/Model' },
    { id: 55, name: 'Medya (Genel Yayın Yönetmeni, İmtiyaz Sahibi Kişi)' },
    {
        id: 56,
        name: 'Medya Çalışanı (Gazeteci, Yazar, Editör, Basın Mensubu, Sunucu, Spiker, Muhabir, Kameraman vb.)'
    },
    { id: 57, name: 'Medya Kuruluşu Sahibi, Ortağı, Yönetim Kurulu Üyesi' },
    { id: 58, name: 'Memur' },
    { id: 59, name: 'Milletvekili / Bakan / Siyasi Parti Başkanı / Siyasi Parti Başkan Yardımcısı' },
    { id: 60, name: 'Modelist/Stilist' },
    { id: 61, name: 'Muhtar' },
    { id: 62, name: 'Mühendis / Mimar / İç Mimar / Dekoratör' },
    { id: 63, name: 'Müsteşar' },
    { id: 64, name: 'Müteahhit' },
    { id: 65, name: 'Nakliyeci' },
    { id: 66, name: 'Noter' },
    { id: 67, name: 'Orta Düzey Yönetici (Kamu)' },
    { id: 68, name: 'Orta Düzey Yönetici (Özel Sektör)' },
    { id: 69, name: 'Otel / Pansiyon / Restoran Sahibi - Ortağı' },
    { id: 70, name: 'Otel/Pansiyon/Restoran Yöneticisi' },
    { id: 71, name: 'Öğrenci' },
    { id: 72, name: 'Öğretmen/Akademisyen' },
    { id: 73, name: 'Pilot / Kaptan' },
    { id: 74, name: 'Psikolog' },
    { id: 75, name: 'Rehber/Tur Operatörü' },
    { id: 76, name: 'Reklam Ve Halkla İlişkiler (Reklamcı)' },
    { id: 77, name: 'Sağlık - Eczacı' },
    { id: 78, name: 'Sağlık - Tıp Doktoru / Diş Hekimi' },
    { id: 79, name: 'Sağlık Görevlisi' },
    { id: 80, name: 'Sanatçı / Yazar / Müzisyen / Ressam / Fotoğrafçı / Tasarımcı' },
    { id: 81, name: 'Satış / Pazarlama Temsilcisi' },
    { id: 82, name: 'Sekreter/Yönetici Asistanı' },
    { id: 83, name: 'Sigortacı' },
    { id: 84, name: 'Sporcu/Menajer' },
    { id: 85, name: 'Şef / Uzman / Yetkili (Özel Sektör)' },
    { id: 86, name: 'Şoför' },
    { id: 87, name: 'Teknik Direktör/Antrenör' },
    { id: 88, name: 'Teknik Görevli/Teknisyen' },
    { id: 89, name: 'Terzi' },
    { id: 90, name: 'Tezgahtar/Kasiyer' },
    { id: 91, name: 'Turizm Acentası/Çalışanı' },
    { id: 92, name: 'Tüccar' },
    { id: 93, name: 'Üst Düzey Emniyet Mensubu / Başkomiser / Komiser vb.' },
    { id: 94, name: 'Üst Düzey Yönetici (Kamu)' },
    { id: 95, name: 'Üst Düzey Yönetici (Özel Sektör)' },
    { id: 96, name: 'Vali / Kaymakam' },
    { id: 97, name: 'Veteriner' },
    { id: 98, name: 'Veznedar/Tahsildar' },
    { id: 99, name: 'Yaşam Koçu' },
    { id: 100, name: 'Yazılımcı/Analist' },
    { id: 101, name: 'Yeminli Mali Müşavir' },
];
