var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import iddiaOptions from '../../../definitions/iddia.options.json';
import { HelpApi } from '../help.api';
import { useParams } from "react-router-dom";
import { helpList } from '../../../store/static.pages.store';
var title = {
    "uyelik": "Üyelik",
    "para-yatirma": "Para Yatırma",
    "para-cekme": "Para Çekme",
    "iddaa-oyunlari": "Tüm İddaa Oyunları (Yeni Dönem)",
    "canli-iddaa": "Canlı İddaa",
    "iddaa": "İddaa",
    "spor-toto": "Spor Toto",
    // "ikramiyeler": "İkramiyeler",
    "sans-oyunlari": "Şans Oyunları",
};
var Questions = function () {
    var params = useParams();
    var data = [];
    var _a = useState([]), helpData = _a[0], setHelpData = _a[1];
    if (helpList.length) {
        data = helpList[0][params.page];
    }
    useEffect(function () {
        getHelpData();
    }, [params.page]);
    function getHelpData() {
        if (!helpList.length) {
            HelpApi.help().then(function (result) {
                helpList.push(result[0]);
                data = helpList[0][params.page];
                if (params.page == "iddaa") {
                    setHelpData(data.filter(function (x) { return x["sport-type"] === 1; }));
                }
                else {
                    setHelpData(data);
                }
            });
        }
        else {
            if (params.page == "iddaa") {
                setHelpData(data.filter(function (x) { return x["sport-type"] === 1; }));
            }
            else {
                setHelpData(data);
            }
        }
    }
    function handleSelectChange(event) {
        setHelpData(data.filter(function (x) { return x["sport-type"] === Number(event.target.value); }));
    }
    var showAnswer = function (key) {
        var element = document.getElementById(key);
        if (element.style.display == 'none') {
            element.style.display = 'block';
        }
        else {
            element.style.display = 'none';
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: title[params.page] }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [params.page === "iddaa" ?
                            _jsx("select", __assign({ onChange: function (e) { return handleSelectChange(e); } }, { children: iddiaOptions.map(function (iddiaOption) {
                                    return _jsx("option", __assign({ value: iddiaOption.id }, { children: iddiaOption.name }), iddiaOption.id);
                                }) }), void 0)
                            : null, helpData ? helpData
                            .map(function (question, key) {
                            return (_jsxs("div", { children: [_jsx("h3", __assign({ className: 'cursor-pointer', onClick: function () { return showAnswer(key); } }, { children: question.question }), void 0), _jsx("div", { className: 'mb-2 help-answers', id: key, style: { display: 'none' }, dangerouslySetInnerHTML: { __html: question.answer } }, void 0)] }, "helpquestionOptions".concat(key)));
                        }) : null] }), void 0)] }), void 0) }, void 0));
};
export default Questions;
