var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../games.css';
import { ApiService } from "../../../lib/http.service";
import { env } from "../../../definitions/global.vars";
import { useAuthContext } from "../../../store/login.store";
import { initialLeftMenu, slugify, sportsMenu } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
import { Storage } from "../../../lib/localstorege.service";
import { gameList } from "../../../store/static.pages.store";
function SuperWheelGame() {
    var iframeContainerRef = useRef(null);
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var _a = useState(null), selectedGame = _a[0], setSelectedGame = _a[1];
    var token = Storage.get('token');
    useEffect(function () {
        initialLeftMenu(false);
        betSlipVisibleSubject.next(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        try {
            if (gameList.length > 0) {
                setSelectedGame(gameList.find(function (el) { return window.location.pathname == "/".concat(slugify(el.code)); }));
            }
            else {
                var api = new ApiService();
                api.start("get", "".concat(env.core_api, "/games/api/list-game/"), {}, false, 60000)
                    .then(function (res) {
                    gameList.splice(0, gameList.length);
                    gameList.push.apply(gameList, res.data);
                    console.log(res.data);
                    setSelectedGame(res.data.find(function (el) { return window.location.pathname == "/".concat(slugify(el.code)); }));
                    console.log(selectedGame);
                });
            }
        }
        catch (e) {
        }
    }, []);
    useEffect(function () {
        console.log(selectedGame);
        if (isAuthenticated && selectedGame) {
            var api = new ApiService();
            api
                .start("post", env.accounting_api + "/games/ert/vendor/auth", { token: token, game_id: selectedGame.id }, true)
                .then(function (res) {
                if (res && res.status && iframeContainerRef.current) {
                    iframeContainerRef.current.src = res.data.url;
                }
            });
        }
    }, [selectedGame]);
    if (isAuthenticated) {
        return (_jsx("div", { children: _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer" }, void 0) }, void 0));
    }
    else {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("h2", __assign({ className: "card-title my-5 text-center" }, { children: "\u00C7ark\u0131felek oynamak i\u00E7in giri\u015F yapmal\u0131s\u0131n\u0131z!" }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center mb-5" }, { children: _jsx("button", __assign({ className: 'btn btn-warning btnLogin', onClick: function () { return navigate('/giris-yap'); } }, { children: "G\u0130R\u0130\u015E YAP" }), void 0) }), void 0)] }), void 0));
    }
}
export default SuperWheelGame;
