var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { helpList } from '../../../store/static.pages.store';
import { HelpApi } from "../help.api";
import { useParams } from "react-router-dom";
var AllGames = function () {
    var _a = useState([]), helpData = _a[0], setHelpData = _a[1];
    var _b = useState([]), optionList = _b[0], setOptionList = _b[1];
    // let optionList: any = [];
    function handleSelectChange(event) {
        console.log(helpList[0][params.page]);
        setHelpData(helpList[0][params.page].filter(function (x) { return x["game-type"] === Number(event.target.value); }));
    }
    var data = [];
    var params = useParams();
    useEffect(function () {
        getHelpData();
    }, []);
    function getHelpData() {
        if (!helpList.length) {
            HelpApi.help().then(function (result) {
                helpList.push(result[0]);
                data = helpList[0][params.page];
                var filteredData = [];
                var _optionList = [];
                for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                    var datum = data_2[_i];
                    if (datum["game-type"] === 1) {
                        filteredData.push(datum);
                    }
                    var gameTypes = { "game-type": datum["game-type"], "game-name": datum["game-name"] };
                    var index = _optionList.findIndex(function (el) { return gameTypes['game-type'] == el['game-type']; });
                    if (index == -1) {
                        _optionList.push(gameTypes);
                    }
                }
                setOptionList(_optionList);
                setHelpData(filteredData);
            });
        }
        else {
            data = helpList[0][params.page];
            var filteredData = [];
            var _optionList = [];
            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                var datum = data_1[_i];
                if (datum["game-type"] === 1) {
                    filteredData.push(datum);
                }
                var gameTypes = { "game-type": datum["game-type"], "game-name": datum["game-name"] };
                var index = _optionList.findIndex(function (el) { return gameTypes['game-type'] == el['game-type']; });
                if (index == -1) {
                    _optionList.push(gameTypes);
                }
            }
            setOptionList(_optionList);
            setHelpData(filteredData);
        }
    }
    var showAnswer = function (key) {
        var element = document.getElementById(key);
        if (element.style.display == 'none') {
            element.style.display = 'block';
        }
        else {
            element.style.display = 'none';
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u015Eans Oyunlar\u0131" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("select", __assign({ onChange: function (e) { return handleSelectChange(e); } }, { children: optionList.map(function (data) {
                                return _jsx("option", __assign({ value: data['game-type'] }, { children: data['game-name'] }), data['game-type']);
                            }) }), void 0), _jsx("h3", {}, void 0), helpData ? helpData
                            .map(function (question, key) {
                            return (_jsxs("div", { children: [_jsx("h3", __assign({ className: 'cursor-pointer', onClick: function () { return showAnswer(key); } }, { children: question.question }), void 0), _jsx("div", { className: 'mb-2 help-answers', id: key, style: { display: 'none' }, dangerouslySetInnerHTML: { __html: question.answer } }, void 0)] }, "helpquestionOptions".concat(key)));
                        }) : null] }), void 0)] }), void 0) }, void 0));
};
export default AllGames;
